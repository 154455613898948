
















  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Component({
    name: 'compareCar',
    components: {},
    mixins: [],
  })
  export default class compareCar extends Vue {

    @Prop({ default: '' }) title!: string;
    @Prop({ default: '' }) desciption!: string;

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }
  }
